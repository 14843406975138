import React, { createContext, useState, useContext } from 'react';

interface BookDemoContextProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const BookDemoContext = createContext<BookDemoContextProps>({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

interface BookModalProviderProps {
  children: React.ReactNode;
}

const BookDemoProvider = ({ children }: BookModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <BookDemoContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
      }}>
      {children}
    </BookDemoContext.Provider>
  );
};

const useBookDemo = () => {
  const context = useContext(BookDemoContext);
  if (!context) {
    console.error('useBookDemo must be used within a BookDemoProvider');
  }
  return context;
};

export { BookDemoContext, BookDemoProvider, useBookDemo };
